import { Container, Nav, Navbar, Button } from "react-bootstrap";
// import logo from "./../Images/dap-dark-text.png";
import logo from "./../Images/dap-dark-text.webp";
import { useSearchParams } from "react-router-dom";
import "./../Theme/themeColors.scss";

const NavBar = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	let ref = searchParams.get("ref");
	if (!searchParams) {
		setSearchParams("");
	}

	console.log(ref);

	const navStyle = {
		backgroundColor: "var(--white-text)",
		borderBottom: "1px solid rgba(0,0,0,.1)",
		fontWeight: "bold",
		padding: "0.75rem ",
	};
	const btnStyle = {
		textDecoration: "none",
		color: "#FFF",
	};
	return (
		<Navbar expand="lg" sticky="top" style={navStyle}>
			<Container>
				<Navbar.Brand href="/">
					<img
						alt="dap logo"
						src={logo}
						width="80"
						height="auto"
						className="d-inline-block align-top"
					/>{" "}
					<small className="logo-text d-block">Delivered as Promised</small>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="navbarScroll" />
				<Navbar.Collapse
					id="navbarScroll"
					className="justify-content-end"
				>
					<Nav
						className="ms-auto my-2 my-lg-0"
						navbarScroll
					>
						<Nav.Link href="/">
							Home
						</Nav.Link>

						<Nav.Link href="/support/faqs">
							FAQs
						</Nav.Link>
						{/* <Nav.Link href="/terms-and-conditions">
							Terms &amp; Conditions
						</Nav.Link> */}
						<Nav.Link href="/contact-us">
							Contact Us
						</Nav.Link>
						{ref && ref !== null ? <a
								href={`https://apps.dap.ng/register?referral=${ref}`}
								style={btnStyle}
								target="_blank"
								rel="noreferrer"
							>
								<Button variant="primary">
								Sign Up
							</Button>
							</a> : <a
								href={`https://apps.dap.ng/register`}
								style={btnStyle}
								target="_blank"
								rel="noreferrer"
							>
								<Button variant="primary">
								Sign Up
							</Button>
							</a>  }
						
						
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
};

export default NavBar;
