import { Container, Row, Col, Button } from "react-bootstrap";
import logo from "../../Images/logo-text-white.webp";
import { Link } from "react-router-dom";
import { BsLinkedin, BsTwitter, BsApple, BsYoutube } from "react-icons/bs";
import { FaGooglePlay } from "react-icons/fa";
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";
import "./footer.css";

const Footer = () => {
	return (
		<>
			{/* <Container fluid>
				<Row className="footer-top">
					<Col md>
						<h3>
							Subscribe Our Newsletter
						</h3>
						<p>
							We're a team of
							non-cynics who truly
							care for our work
						</p>
					</Col>
					<Col md>
						<Form>
							<Row className="align-items-center">
								<Col md>
									<Form.Label
										htmlFor="inlineFormInput"
										visuallyHidden
									>
										Email
									</Form.Label>
									<Form.Control
										type="email"
										placeholder="Enter email"
									/>
								</Col>

								<Col xs="auto">
									<Button
										type="submit"
										className="mb-2"
									>
										Subscribe
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
			</Container> */}
			<div className="bg2">
				<Container className="footer-bottom">
					<Row>
						<Col>
							<div className="brand-summary">
								<img
									src={
										logo
									}
									alt=""
								/>
								<p className="footer-text">
									DAP
									helps
									you
									easily
									send and
									receive
									payment
									with
									confidence,
									increase
									your
									earnings
									and
									expand
									your
									business.
								</p>
							</div>
						</Col>
						<Col>
							<div className="footer-nav">
								<p>
									Quick
									Links
								</p>
								<ul className="footer-link-list">
									<li>
										<Link to="/support/faqs">
											FAQ
										</Link>
									</li>
									<li>
										<Link to="/contact-us">
											Contact
										</Link>
									</li>
									<li>
										<Link to="/terms-and-conditions">
											Terms
											&amp;
											Privacy
										</Link>
									</li>
								</ul>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="footer-btns btns">
								<Button className="download-btn">
									<a
										href="https://apps.apple.com/ng/app/dap-delivered-as-promised/id1591431787"
										target="_blank"
										rel="noreferrer"
									>
										<BsApple className="btn-icon" />
										<span className="btn-text">
											<span>
												Download
												On
											</span>
											<span>
												<strong>
													App
													Store
												</strong>
											</span>
										</span>
									</a>
								</Button>
								<Button className="download-btn">
									<a
										href="https://play.google.com/store/apps/details?id=com.afritsolutions.dap"
										target="_blank"
										rel="noreferrer"
									>
										<FaGooglePlay className="btn-icon" />
										<span className="btn-text">
											<span>
												Download
												On
											</span>
											<span>
												<strong>
													Google
													Play
												</strong>
											</span>
										</span>
									</a>
								</Button>
							</div>
						</Col>
						<Col>
							<div className="social-links">
								<ul className="social-link-list">
									<li>
										<a
											href="https://twitter.com/DAPitNigeria"
											rel="noopener noreferrer"
											target="_blank"
										>
											<BsTwitter />
										</a>
									</li>
									<li>
										<a
											href="https://instagram.com/dapitnigeria"
											rel="noopener noreferrer"
											target="_blank"
										>
											<AiFillInstagram />
										</a>
									</li>
									<li>
										<a
											href="https://facebook.com/DAPitNigeria"
											rel="noopener noreferrer"
											target="_blank"
										>
											<AiFillFacebook />
										</a>
									</li>
									<li>
										<a
											href="https://linkedin.com/company/dapnigeria"
											rel="noopener noreferrer"
											target="_blank"
										>
											<BsLinkedin />
										</a>
									</li>
									<li>
										<a
											href="https://www.youtube.com/channel/UCzdd1n7zMGkgQ1ctBzmNFxA/videos"
											rel="noopener noreferrer"
											target="_blank"
										>
											<BsYoutube />
										</a>
									</li>
								</ul>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default Footer;
