import { useRef, useEffect } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import Typed from "typed.js";
import { BsApple } from "react-icons/bs";
import { FaGooglePlay } from "react-icons/fa";
import img4 from "../../Images/4.webp";
import img5 from "../../Images/5.webp";
import img6 from "../../Images/6.webp";
import img7 from "../../Images/7.webp";
import img8 from "../../Images/8.webp";
import img9 from "../../Images/9.webp";
import airtime from "../../Images/airtime.webp";
import bank from "../../Images/bank.webp";
import bills from "../../Images/bills.webp";
import dapIcon from "../../Images/dap-white.webp";
//import videoImg from "../../Images/video.webp";
import heroImg from "../../Images/1.webp";

import "./herosection.css";

const HeroSection = () => {
    const cards = [
        {
            title: "Buy Airtime/Data",
            img: airtime,
        },
        {
            title: "Bank Transfer",
            img: bank,
        },
        {
            title: "Pay Utility/ Bills",
            img: bills,
        },
        {
            title: "And More",
            img: dapIcon,
        },
    ];
    const featureDesc = [
        {
            header: "Pay Vendor/Artisan With DAP Safe Mode",
            text: `Buy products and services. Ask for DAP account and pay`,
            img: img7,
        },
        {
            header: "Choose a time for automatic release",
            text: `Set the agreed delivery date for DAP to release the fund automatically`,
            img: img9,
        },
        {
            header: "Release Money",
            text: "When the vendor/artisan delivers, you can release the fund. Or sit and relax for it to be released automatically on set date",
            img: img8,
        },
    ];

    const ref = useRef(null);

    useEffect(() => {
        const typed = new Typed(ref.current, {
            strings: [
                "Tired of being scammed by vendors?",
                "Want to control your money, even after you send it?",
                "Want to pay a vendor/artisan but you are scared?",
                "\n Want to get exactly what you paid for?",
                "Want to gain trust of your customers?",
                "Want a single app for all your financials?",
                "Trust,\n\n `Confidence,`^500\n\n `Convenience,`^500\n\n ",
            ],
            startDelay: 100,
            typeSpeed: 100,
            backSpeed: 100,
            backDelay: 1000,
            smartBackspace: true,
            loop: true,
            fadeOut: true,
            showCursor: true,

        });

        return () => {
            typed.destroy();
        };
    }, []);

    return (
        <>
            <div className="hero-section bg1">
                <Container>
                    <Row>
                        <Col className="hero-text">
                            <div style={{ height: "315px", marginTop: "10px", display:'flex',  alignItems:'center' }}>
                                <h1 className="big-text">
                                    <span ref={ref}></span>
                                </h1>
                            </div>
                            <div></div>
                            <h1 className="big-text">
                                <span className="primary-color-text d-block">
                                    Think DAP
                                </span>
                            </h1>

                            <p className="fw-bold">
                                DAP - "Delivered As Promised" is built to
                                protect you from fraud by giving you control over your money even after you send it.
                            </p>
                            <div className="btns">
                                <Button className="download-btn">
                                    <a
                                        href="https://apps.apple.com/ng/app/dap-delivered-as-promised/id1591431787"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <BsApple className="btn-icon" />
                                        <span className="btn-text">
                                            <span>Download On</span>
                                            <span>
                                                <strong>App Store</strong>
                                            </span>
                                        </span>
                                    </a>
                                </Button>
                                <Button className="download-btn">
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.afritsolutions.dap"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <FaGooglePlay className="btn-icon" />
                                        <span className="btn-text">
                                            <span>Download On</span>
                                            <span>
                                                <strong>Google Play</strong>
                                            </span>
                                        </span>
                                    </a>
                                </Button>
                            </div>
                        </Col>
                        <Col className="hero-img">
                            <img src={heroImg} alt="hero" />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <Row>
                    <Col className="feature-img">
                        <img src={img4} alt="dap-feature" />
                    </Col>
                    <Col className="feature-text">
                        <h2
                            className="feature-bold-text"
                            style={{ width: "80%" }}
                        >
                            {" "}
                            Like most people, you can
                            Relate to ...
                        </h2>
                        <ul className="feature-ul-list">
                            <li>Getting blocked after paying for an item</li>
                            <li>Delay in delivery time</li>
                            <li>"What I Ordered Vs What I Got"</li>
                            <li>
                                Not getting refunds even when transactions
                                promises are not met
                            </li>
                            <li>Vendors being played by buyers</li>
                            <li>
                                Vendors losing potential customers due to trust
                                issues
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Row className="reverse">
                    <Col className="feature-text">
                        <h3
                            className="feature-bold-text text-center"
                            style={{ width: "80%" }}
                        >
                            Other Escrow Systems,{" "}
                            <span className="primary-color-text">
                                {" "}
                                more problems
                            </span>
                        </h3>
                        <ul className="feature-ul-list">
                            <li>Involves third-party holding the money</li>
                            <li>Has complicated transaction process</li>
                            <li>Ask for exorbitant charges</li>
                            <li>Has very lenghty wait time</li>
                        </ul>
                    </Col>
                    <Col className="feature-img">
                        <img src={img5} alt="dap feature" />
                    </Col>
                </Row>
            </Container>
            <div className="bg1 safe-mode">
                <Container>
                    <Row>
                        <Col className="feature-img">
                            <img
                                src={img6}
                                alt="dap feature"
                                className="resize-img"
                            />
                        </Col>
                        <Col className="feature-text">
                            <h2>We're Introducing</h2>
                            <h3 className="primary-color-text">
                                DAP Safe Mode
                            </h3>
                            <p
                                style={{
                                    fontSize: "16px",
                                }}
                            >
								 <ul className="feature-ul-list">
                            <li>You are in control of the money you paid a vendor/artisan - no third-party!</li>
                            <li>Simple and easy. Ask for the vendor bank account number (any bank) and pay with dap - no long process</li>
                            <li>All transactions to other dap users are free - no hidden charges. You get some free bank transfer too</li>
                            <li>You control your money, you control your time, you control everything!</li>
                            <li>No middle man. No hidden charges</li>
                        </ul>
						
                                
                                <br />
                                You as a vendor or artisan no longer loose your
                                customers due to lack of trust. And your money
                                goes straight to your account.
                                <br />
                                <br />
                                
                            </p>
                            <p className="primary-color-text ">
                                Just Dap It...
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <Row className="flex-column">
                    <Col className="feature-text">
                        <h2 className="text-center me-2">How it works</h2>
                    </Col>
                    {featureDesc.map((desc, idx) => (
                        <Col className="feature-desc" key={idx}>
                            <div className="feature-desc-text">
                                <h3>{desc.header}</h3>
                                <p className="grey-colored-text">{desc.text}</p>
                                {desc.button ? (
                                    <Button
                                        variant="outline-primary"
                                        style={{
                                            margin: "0.3rem",
                                        }}
                                    >
                                        {desc.button}
                                    </Button>
                                ) : null}
                            </div>
                            <div className="feature-desc-img">
                                <img src={desc.img} alt="dap feature" />
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
            <div className="bg2">
                <Container fluid>
                    <Row className="flex-column">
                        <Col>
                            <h3 className="white-text text-center">
                                Do More with Dap
                            </h3>
                            <p className="white-text text-center">
                                One stop application for all your finacial
                                transactions
                            </p>
                        </Col>
                        <Col>
                            <div className="gradient-container">
                                {cards.map((card, idx) => (
                                    <Card
                                        key={idx}
                                        className="mb-2 feature-card"
                                    >
                                        <Card.Img
                                            variant="top"
                                            src={card.img}
                                        />
                                        <Card.Body>
                                            <Card.Text>{card.title}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <Row className="flex-column feature-text">
                    <Col>
                        <h2 className="text-center">See DAP in action</h2>
                        <p className="text-center">
                            Watch videos on how to get the best from DAP
                        </p>
                    </Col>
                    <Col className="feature-video">
                        <iframe
                            width="560"
                            height="315"
                            src="https://www.youtube.com/embed/videoseries?list=PLZMmixh2iM-Z2kQpbGQ0s8nJJbWFmseE8"
                            title="Dap Explained"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default HeroSection;
