import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTop from "./utils/ScrollToTop";
import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Main from "./Layouts/Main";
import { FourZeroFour } from "./components";
import { LandingPage, Contact, FAQ, Privacy } from "./pages";
import ReactGA from "react-ga";

ReactGA.initialize("UA-216946751-1");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {

	useEffect(() => {
		ReactGA.pageview(
			window.location.pathname + window.location.search
		);
	});
	return (
		<>
			<Router>
				<HelmetProvider>
					<ScrollToTop />
					<Helmet>
						<title>
							DAP | Payment made safer
						</title>
						<meta
							name="description"
							content="DAP helps you send and receive payment with trust, earn and expand your business in Africa with confidence. Dap offers peer-peer transactions, Payment of utility bills, Connect with artisans, Request money, Creating vouchers and more"
						/>
						<meta
							name="keywords"
							content="dap, dap ng, delivered as promised, escrow, utilities, paybill, payment, fintech, peer-peer transactions, Payment of utility bills, Connect with artisans, Request money and Create vouchers"
						/>
					</Helmet>

					<Main>
						<Routes>
							<Route
								path="/"
								element={
									<LandingPage />
								}
							/>

							<Route
								path="/support/faqs"
								element={
									<FAQ />
								}
							/>
							<Route
								path="/privacy-policy"
								element={
									<Privacy />
								}
							/>
							<Route
								path="/terms-and-conditions"
								element={
									<Privacy />
								}
							/>
							<Route
								path="/contact-us"
								element={
									<Contact />
								}
							/>
							<Route
								path="*"
								element={
									<FourZeroFour />
								}
							/>
						</Routes>
					</Main>
				</HelmetProvider>
			</Router>
		</>
	);
}

export default App;
