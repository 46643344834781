export const faqs = [{
        q: "What is DAP?",
        a: `DAP is a digital payment
        system that ensures
        payment security for
        parties involved in a
        transaction. With DAP,
        you can send money with
        confidence and receive
        payment with assurance.`,
    },
    {
        q: "What does DAP offer?",
        a: `The “DAP safe mode” and “DAP artisan” feature.`,
    },
    {
        q: "What is “DAP safe mode”?",
        a: `DAP offers each user two (2) wallets; the Available wallet and Pending wallet. “DAP safe mode” allows you control your money, even after you’ve sent it. This means that even though you can send money directly to a DAP user wallet or bank account, “DAP safe mode” allows you to fix a released date for money sent. If a release date is not fixed, DAP will automatically release the money in 24 hours. A money sent but NOT released remains in the recipient PENDING BALANCE. When released, it goes to AVAILABLE BALANCE.`,
    },
    {
        q: "Who holds the money sent?",
        a: "The recipient holds the money. Money sent but NOT released remains in the recipient PENDING BALANCE. When released, it goes to AVAILABLE BALANCE. Only money in AVAILABLE BALANCE is accessible to the recipient."
    },
    {
        q: "Does the sender have access to the money sent?",
        a: "The money sent but not released is in the recipient pending balance and it is neither available to the sender nor the recipient except when released or through the intervention of our support."
    },
    {
        q: "What is DAP artisan?",
        a: "DAP artisan seamlessly connect householders with nearby artisans while also processing every payment involved in their transaction."
    },
    {
        q: "When can I use DAP?",
        a: "DAP is designed to give peace of mind either when you are in need of the service of an artisan or whenever you are in doubt about doing business with someone you don’t know. It is totally up to you."
    },
    {
        q: "Who can use DAP?",
        a: "DAP can be used by individuals for peer to peer transactions and businesses that need payment security for their transactions. Businesses like Marketplace platforms who cater to two sets of users (Buyers and Sellers) can also use DAP to facilitate trust between transacting parties on their platform."
    },



]