import styled from "styled-components";
// import styled, { ThemeProvider } from "styled-components";
// import Theme from "../Theme/Theme";
// import { Global, lightTheme, darkTheme } from "../Theme/Global";
// import { BsLightbulbFill, BsLightbulbOffFill } from "react-icons/bs";
import { MessengerIcon, Navbar, Footer } from "../components";
const Container = styled.div``;

const Main = ({ children }) => {
	// const [theme, toggleTheme] = Theme();
	// const themeMode = theme === "light" ? lightTheme : darkTheme;

	// theme={themeMode}
	return (
		<>
			{/* <ThemeProvider theme={themeMode}/> */}
			<Container>
				{/* <Global /> */}
				<Navbar />

				{/* <div
						onClick={toggleTheme}
						className="toggle-icon"
					>
						{theme === "light" ? (
							<BsLightbulbOffFill
								style={{
									color: "#444",
									fontSize: "30px",
								}}
							/>
						) : (
							<BsLightbulbFill
								style={{
									color: "#fff",
									fontSize: "30px",
								}}
							/>
						)}
						
					</div> */}
				{children}
				<MessengerIcon />
				<Footer />
			</Container>
			{/* </ThemeProvider> */}
		</>
	);
};

export default Main;
