import { BsWhatsapp } from "react-icons/bs";

const MessengerIcon = () => {
	return (
		
			<a
				href="https://wa.me/2347034481876?text=I'm%20interested%20in%20dap%20app"
				target="_blank"
				rel="noreferrer"
				className="messenger-icon"
			>
				<BsWhatsapp className="whatsapp-icon" />
			</a>
		
	);
};

export default MessengerIcon;
