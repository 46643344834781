import { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { FaLocationArrow, FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import emailjs from "emailjs-com";
import "./contact-us.css";

const Contact = () => {
	const [successMessage, showSuccessMessage] = useState(false);

	const handleMessage = (e) => {
		e.preventDefault();

		emailjs.sendForm(
			"service_lvz7p2i",
			"template_zk6p4ch",
			e.target,
			"user_ESWxBlpU0lvJVA2IDaXvq"
		)
			.then((res) => {
				console.log(res);
			})
			.catch((err) => console.log(err));
		e.target.reset();
		showSuccessMessage(true);
	};

	useEffect(() => {
		setTimeout(() => {
			if (successMessage) {
				showSuccessMessage(false);
			}
		}, 10000);
	}, [successMessage]);

	return (
		<Container>
			<Helmet>
				<title>DAP | Contact</title>
				<meta
					name="description"
					content="Dap support is available 24/7 to help you with any payment issues"
				/>
				<meta
					name="keywords"
					content="dap, dap ng, delivered as promised, escrow, utilities, paybill, payment, fintech, peer-peer transactions, Payment of utility bills, Connect with artisans, Request money and Create vouchers"
				/>
			</Helmet>

			<Row>
				<Col className="contact-header">
					<h2>Contact Us</h2>

					<p className="lead mb-5">
						We endeavour to answer all
						enquiries within 24 hours on
						business days
					</p>
				</Col>
			</Row>
			<Row className="align-items-stretch mb-5">
				<Col md={4} className="contact-info">
					<div>
						<h4 className="text-center">
							Contact Information
						</h4>
						<p className="text-center">
							Give us a call or drop
							by anytime.
						</p>

						<hr className="my-4" />

						<ul className="list-unstyled">
							<li className="d-flex pb-3">
								<div className="contact-icon mr-3">
									<span className="rounded-circle p-3">
										<FaLocationArrow />
									</span>
								</div>
								<div className="contact-text">
									<h5 className="mb-1">
										Company
										Location
									</h5>
									<p>
										Road
										2,
										House
										28A,
										Westend
										Estate,
										Lekki,
										Lagos,
										Nigeria.
									</p>
								</div>
							</li>
							<li className="d-flex pb-3">
								<div className="contact-icon mr-3">
									<span className="rounded-circle p-3">
										{" "}
										<FaPhoneAlt />{" "}
									</span>
								</div>
								<div className="contact-text">
									<h5 className="mb-1">
										Phone
										Number
									</h5>
									<p>
										07034481876
									</p>
								</div>
							</li>
							<li className="d-flex pb-3">
								<div className="contact-icon mr-3">
									<span className="rounded-circle p-3">
										{" "}
										<FaEnvelope />{" "}
									</span>
								</div>
								<div className="contact-text">
									<h5 className="mb-1">
										Email
										Address
									</h5>
									<p>
										info@dap.ng
									</p>
								</div>
							</li>
						</ul>
					</div>
				</Col>
				<Col md={8} className="contact-form">
					{successMessage ? (
						<div className="success-message">
							<h3 className="text-center">
								Thank You For
								Reaching Out!
								Your message has
								been sent
								successfully.
							</h3>
							<p className="text-center primary-color-text">
								You'll get a
								response soon.
							</p>
						</div>
					) : null}
					<Form
						onSubmit={handleMessage}
						className={
							successMessage
								? "no-display"
								: ""
						}
					>
						<Form.Group controlId="name">
							<Form.Label>
								Full Name
							</Form.Label>
							<Form.Control
								type="text"
								placeholder="Your name"
								name="user_name"
								required
							/>
						</Form.Group>
						<Form.Group controlId="user_email">
							<Form.Label>
								Email
							</Form.Label>
							<Form.Control
								type="email"
								placeholder="Your email "
								name="user_email"
								required
							/>
						</Form.Group>
						<Form.Group controlId="subject">
							<Form.Label>
								Subject
							</Form.Label>
							<Form.Control
								type="text"
								placeholder="Subject "
								name="subject"
								required
							/>
						</Form.Group>
						<Form.Group controlId="message">
							<Form.Label>
								Your Message
							</Form.Label>
							<Form.Control
								as="textarea"
								placeholder="Type message here"
								style={{
									height: "6.4rem",
								}}
								name="message"
								required
							/>
						</Form.Group>

						<Button
							variant="primary"
							type="submit"
							className="form-control"
						>
							Send Message
						</Button>
					</Form>
				</Col>
			</Row>
		</Container>
	);
};

export default Contact;
