import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import "./terms-and-conditions.css";

const Privacy = () => {
	return (
		<>
			<Container>
				<Helmet>
					<title>
						DAP | Privacy Policy
					</title>
					<meta
						name="description"
						content="Learn about the Policy guilding data collected for our peer-peer transactions, Payment of utility bills, Connecting with artisans, Requesting money, Creating vouchers and other services we offer "
					/>
					<meta
						name="keywords"
						content="dap, dap ng, delivered as promised, escrow, utilities, paybill, payment, fintech, peer-peer transactions, Payment of utility bills, Connect with artisans, Request money and Create vouchers"
					/>
				</Helmet>
				<Row className="t-c-header">
					<Col>
						<h2 className="text-center mb-0">
                        Privacy Policy
						</h2>
					</Col>
				</Row>
			</Container>
			<Container>
				<Row className="terms">
					<Col>
						<h4>Background</h4>
						<p>
							Dap™ is a trademark of Afrits Innovations Limited (“Afrits”). As the data collector, Afrits ("we" “us” “our”) are committed to protecting and respecting your privacy. This policy sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. By visiting our website,{" "}
							<a href="https://dap.ng">
								https://dap.ng
							</a> or using Dap mobile application hosted on the Google Play Store or Apple Play Store (collectively “our site”) you are accepting and consenting to the practices described in this policy. The collection and processing of your personal data is in accordance with the National Information Technology Development Agency Act 2007 (the “Act”), Nigeria Data Protection Regulation 2019 (the “Regulations”) and the provisions and prescriptions of Section 5; Part 1 and Part 2 of National Information Systems and Network Security Standards and Guidelines
						</p>
						
						<h4>
							Information We Collect
						</h4>
						<p>We may collect and process the following data about you:</p>

                        <strong>1. Information you give us when you use our app or website:</strong>
                        <p>
                        We collect and use any information you supply when you interact with any of our touch points. When you open an account with us, you share details like your name, BVN, Means of Identification, address and a picture. By using the app to transact, you also share details of your transactions with us. Additionally, we may request explicit permission to see other information like your address book, photos and data from your camera.
                        </p>


						<ul
							style={{
								listStyle: "disc",
							}}
						>
							<li>Details you give when you sign up for a Dap account, like your BVN, name, date of birth, gender, phone number, residential address, and email address.</li>
                            <li>Your profile picture.</li>
                            <li>Your credit card details should you choose to transact with your credit cards.</li>
                            <li>Also, if you choose to delete your Dap account, we will delete any data we have previously collected from you.</li>
                            <li>Information you give us through the in-app chat so we can help you.</li>
						</ul>
						<strong>
                        2. Information you give us when you contact us
						</strong>
						<p>
                        If you contact via other means than the in-app chat, we collect the following information so we can answer your questions or take action.
						</p>

                        <ul
							style={{
								listStyle: "disc",
							}}
						>
							<li>The phone number you’re calling from and information you give us during the call</li>
                            <li>The email address you use and the contents of your email (and any attachments).</li>
                            <li>Public details from your social media profile (like Facebook, Instagram or Twitter) if you reach out to us via these platforms, and the contents of your messages or posts to us.</li>
						</ul>

						<strong>
                        3. Information we collect when you use our mobile app
						</strong>
						
						<ul
							style={{
								listStyle: "disc",
							}}
						>
							<li>
                            The mobile network and operating system you use,
							</li>
							<li>
                            Your IP address and device ID
							</li>
							<li>
                            Your phone contacts so you can make airtime purchases or pay contacts on Dap
							</li>
						</ul>

						<h4>
                        Sharing your contacts with us
						</h4>
						<p>
						Our mobile app collects your contacts data to enable you send, receive and request money from your contacts on our platform. We do not store or use your contacts data outside this purpose.						
						</p>
						<strong>
                        Consent to share contact data
						</strong>
						<p>Before we collect your contact, we will present a consent form on our app, with the title: "Share your contacts with dap?" and a brief description of how we collect, use and process the data. By clicking "I Agree", you concent to uploading your contact information to our server for processing.</p>

						<strong>
                        Withdraw consent to share contact data
						</strong>
						<p>You can always withdraw your consent at the settings on our mobile app. When when you uncheck "I want to share contact data" in the settings, you withdraw your permission and we immidiately stop to collect, upload or process your contacts.</p>
						
						

						<h4>Cookies</h4>
						<p>
						Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. By visiting our website, you consent to the placement of cookies and beacons in your browser and HTML-based emails in accordance with this Privacy Policy.
						</p>

                        <h4>
                        How do we use your information?
						</h4>
                        <p>The Nigerian Data Protection Regulation says we need to have a lawful basis for using your personal information. At least one of the following must apply: contractual or legal duty, legitimate interest, public interest, vital individual interest or consent.</p>


                        <strong>
                        Contractual Obligation
						</strong>
                        <p>We collect certain data from you to fulfil the contract we have with you, or to enter into a contract with you. </p>
						<ul
							style={{
								listStyle: "disc",
							}}
						>
							<li>
                            We use this data to give you the services we agreed to in line with our terms and conditions;
							</li>
							<li>
                            To send you messages about your account and other services you use if you get in touch;
							</li>
							<li>
							To notify you about changes to our standard terms of service or to our business;
							</li>
							<li>To ensure that content from our site is presented in the most effective manner for you and for your device.</li>
							<li>
							And to investigate and resolve complaints and other issues;
							</li>
						</ul>

                        <strong>
                        Legal Duty
						</strong>
                        <p>We have to ensure we aren’t breaking any laws by banking you by preventing illegal activities like money laundering and fraud. To do this, we need your data to confirm your identity when you sign up or get in touch.</p>

                        <strong>
                        Consent
						</strong>
                        <p>For certain information, we’ll ask for your consent. We’ll ask for your consent to:</p>
                        <ul
							style={{
								listStyle: "disc",
							}}
						>
							<li>
                            Market and communicate our products and services. You can always unsubscribe from receiving these if you want to.
							</li>
							<li>
                            View your contact list for airtime purchases if you’ve authorised it.
							</li>
						</ul>

						<p>By ticking the “I agree” to the Terms and Conditions, the contact sync consent message or other concent, which you hereby adopt as your electronic signature, you consent and agree to our collection and use of those information</p>

                        <h4>Who do we share your information with?</h4>
                        <p>In some instances, we will share the information provided with some third parties that we do business with, like card producers and credit agencies, or with law enforcement agencies when required.</p>

                        <h4>How long do we keep your data?</h4>
                        <p>We keep your information as long as you are a Dap customer and for any additional period as required under applicable law or regulations.</p>

                        <p>These retention requirements supersede any right to erasure requests under applicable data protection laws. We cannot therefore erase your data in relation to your Dap Account until after this time period, however we can assure you that your data will be held safely and securely whilst under our supervision.</p>

                        <h4>When do we delete your data?</h4>
                        <p>We are basically storing and processing your personal data only as long as it is necessary to perform our obligations under the agreement with you or as long as the law requires us to store it.</p>

                        <p>If you choose to delete your Dap account, we will delete any data you have previously given us.</p>

                        <h4>
                        What are your rights?
                        </h4>
                        <p>You have the right to access and request the information that we have for you in our records. You may also ask us to delete our record of you, restrict the way in which we use your personal information, request a copy of the information we hold about you, ask that we update the personal information we hold about you or correct such personal information which you think is incorrect or incomplete, and we will grant this request as long as we’re legally allowed to. You also have the right to object to us using your information for our marketing purposes or any additional services we may be offering you. Lastly, you may also withdraw any consent you’ve previously given us.</p>

                        <h4>How to make a complaint</h4>
                        <p>At Dap, we’re extremely committed to respecting and protecting your information. If you have any worries or reservations about your personal information, please contact our Data Protection Officer by:</p>

                        <ul
							style={{
								listStyle: "disc",
							}}
						>
							<li>
                            Sending a message via the app.
							</li>
                            <li>
                            Sending a message to any of our social media handles.
							</li>
							<li>
                            Emailing us at info@dap.ng
							</li>
						</ul>

                        <h4>Changes to this document</h4>
                        <p>This document will be reviewed on a yearly basis by our Data Protection Officer. If we make any changes, we’ll add a note to this page and if they’re significant changes we’ll let you know by email.</p>
                        
                        <p>This policy was last updated on 11 August 2022.</p>
						
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Privacy;
