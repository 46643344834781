import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
// import img5 from "../../Images/5.png";
import img5 from "../../Images/5.webp";
import "./fourzerofour.css";

const FourZeroFour = () => {
	let navigate = useNavigate();

	return (
		<>
			<Container>
				<Helmet>
					<title>DAP | 404 Not Found</title>
					<meta
						name="description"
						content="page not found"
					/>
					<meta
						name="keywords"
						content="dap, dap ng, delivered as promised, escrow, utilities, paybill, payment, fintech, peer-peer transactions, Payment of utility bills, Connect with artisans, Request money and Create vouchers"
					/>
				</Helmet>
				<Row className="fof">
					<Col className="fofimg">
						<img
							src={img5}
							alt="404 error page"
						/>
					</Col>
					<Col className="fofmessage">
						<h2>Opps!</h2>
						<h5>
							We could not find the
							page you are looking
							for.
						</h5>
						<h5>
							Maybe it got moved?
							Deleted? Or never
							existed in the first
							place?
						</h5>

						<Button
							variant="primary"
							onClick={() => {
								navigate("/");
							}}
						>
							Go Back Home
						</Button>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default FourZeroFour;
