import { Container, Row, Col } from "react-bootstrap";
import { faqs } from "../../utils/faqs";
import { MessengerIcon } from "../../components";
import { Helmet } from "react-helmet-async";
import "./faq.css";

const FAQ = () => {
	return (
		<Container>
			<Helmet>
				<title>DAP | Frequently Asked Questions</title>
				<meta
					name="description"
					content="Learn more about our peer-peer transactions, Payment of utility bills, Connect with artisans, Request money and Create vouchers "
				/>
				<meta
					name="keywords"
					content="dap, dap ng, delivered as promised, escrow, utilities, paybill, payment, fintech, peer-peer transactions, Payment of utility bills, Connect with artisans, Request money and Create vouchers"
				/>
			</Helmet>
			<Row className="faq-header">
				<Col>
					<h2 className="text-center mb-3">
						Frequently Asked Questions
					</h2>
				</Col>
			</Row>

			<Row xs={1} md={2} lg={2} className="faqs">
				{faqs.map((faq, idx) => (
					<Col key={idx} className="faq-column">
						<h5>
							<span className="primary-color-text">
								Q.
							</span>
							<span>{faq.q}</span>
						</h5>
						<p>{faq.a}</p>
					</Col>
				))}

				<Col className="faq-column">
					<h5>
						<span className="primary-color-text">
							Q.
						</span>
						<span>How can I use DAP?</span>
					</h5>
					<p>
						DAP can be used in different
						ways, depending on your need.
						Individuals can sign up and use
						DAP for the following:
					</p>
					<ul
						style={{
							listStyle: "none",
							marginLeft: "-2rem",
						}}
					>
						<li>Peer-peer transactions </li>
						<li>
							Payment of utility bills{" "}
						</li>
						<li>Connect with artisans </li>
						<li>
							Request money and Create
							vouchers, etc.{" "}
						</li>
					</ul>
					DAP can be used in different ways,
					depending on your need. Individuals can
					sign up and use DAP for the following:
					<ul
						style={{
							listStyle: "none",
							marginLeft: "-2rem",
						}}
					>
						<li>
							Integration DAP on their
							business platforms for
							payment processing.{" "}
						</li>
						<li>Payment scheduling. </li>
					</ul>
				</Col>
				<Col className="faq-column">
					<h5>
						<span className="primary-color-text">
							Q.
						</span>
						<span>
							What if there is a
							dispute?
						</span>
					</h5>
					<p>
						Should a dispute arise over the
						course of a transaction, each
						user can raise a call for
						dispute resolution. If the
						dispute cannot be resolved
						mutually by the parties
						involved, DAP arbitration system
						comes in for fair and prompt
						resolution. While a transaction
						is in dispute, the funds remain
						inaccessible to none of the
						parties until the dispute has
						been resolved.
					</p>
				</Col>
				<Col className="faq-column">
					<h5>
						<span className="primary-color-text">
							Q.
						</span>
						<span>
							What is DAP process?
						</span>
					</h5>
					<p>
						DAP reduces the risk of fraud
						and insecurities involved in
						online transactions by providing
						a safe platform for payment
						processing.
					</p>
					<ul>
						<li>
							<strong>
								Buyer and Seller
								agree to terms
							</strong>{" "}
							-Both the buyer and
							seller agree on the
							terms of their
							transaction together
							with how long they want
							the transaction to last.
						</li>
						<li>
							<strong>
								Buyer set a
								released date
								based on the
								agreed terms{" "}
							</strong>{" "}
							- The Buyer's fund is
							sent to the seller's
							pending wallet. If no
							date is set, DAP uses 24
							hours.
						</li>
						<li>
							<strong>
								Seller delivers
								services/merchandise{" "}
							</strong>{" "}
							- The Seller delivers
							services/merchandise,
							based on the transaction
							agreement within the
							stipulated time.
						</li>
						<li>
							<strong>
								Funds is
								released to the
								Seller
							</strong>{" "}
							- DAP automatically
							release fund to the
							Seller and the fund is
							made available for
							withdrawal.
						</li>
					</ul>
				</Col>
				<Col className="faq-column">
					<h5>
						<span className="primary-color-text">
							Q.
						</span>
						<span>
							Can the release date be
							rescheduled?
						</span>
					</h5>
					<p>
						Yes, it can, provided a reason
						was stated and no dispute was
						raised by the potential
						recipient.
					</p>
				</Col>
			</Row>
			<MessengerIcon />
		</Container>
	);
};

export default FAQ;
